import React from "react";
import Button from "components/utils/button.component";
import { useNavigate } from "@reach/router";
import { AppState } from "store/reducers";
import { connect } from "react-redux";
import { EventReducerInterface } from "store/reducers/event.reducer";

interface MoveToLiveWidgetProps {
    event: EventReducerInterface;
}

const MoveToLiveWidget: React.FC<MoveToLiveWidgetProps> = (props) => {
    const latestEvent = props.event.events
        .filter((event) => event.live_screen_enabled)
        .sort(
            (a, b) =>
                new Date(a.start_date).getTime() -
                new Date(b.start_date).getTime()
        )[0];

    const navigate = useNavigate();

    return latestEvent ? (
        <div className="bg-white shadow rounded-md col-span-6 my-2 mx-2">
            <div className="px-4 py-5 sm:p-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Siirry livesivulle
                </h3>
                <div className="mt-2 sm:flex sm:items-start sm:justify-between">
                    <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
                        <Button
                            color="primary"
                            type="button"
                            onClick={() => navigate("/live/" + latestEvent.id)}
                            fullWidth={true}
                        >
                            Siirry
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <span />
    );
};

const mapStateToProps = (state: AppState) => ({
    event: state.event,
});

export default connect(mapStateToProps)(MoveToLiveWidget);
